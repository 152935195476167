import { Icon } from '@iconify/react';
import pieChart2Fill from '@iconify/icons-eva/pie-chart-2-fill';
import fileTextFill from '@iconify/icons-eva/file-text-fill';
import MapFill from '@iconify/icons-eva/map-fill';
import RouteIcon from '@mui/icons-material/Route';
import EventIcon from '@mui/icons-material/Event';
import ModeOfTravelIcon from '@mui/icons-material/ModeOfTravel';
import WrongLocationIcon from '@mui/icons-material/WrongLocation';
import SummarizeIcon from '@mui/icons-material/Summarize';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import EventRepeatIcon from '@mui/icons-material/EventRepeat';
import NotificationsActiveRoundedIcon from '@mui/icons-material/NotificationsActiveRounded';
import clockOutline from '@iconify/icons-eva/clock-outline';
import ManageSearchRoundedIcon from '@mui/icons-material/ManageSearchRounded';
import CloudDoneOutlinedIcon from '@mui/icons-material/CloudDoneOutlined';
import FindInPageRoundedIcon from '@mui/icons-material/FindInPageRounded';
import DnsRoundedIcon from '@mui/icons-material/DnsRounded';
import EventNoteRoundedIcon from '@mui/icons-material/EventNoteRounded';
import WorkIcon from '@mui/icons-material/Work';
import ChromeReaderModeIcon from '@mui/icons-material/ChromeReaderMode';
// ----------------------------------------------------------------------

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const SidebarReportConfig = [
    {
        title: 'Route',
        path: '/report/route',
        icon: <RouteIcon />
    },
    {
        title: 'Event',
        path: '/report/event',
        icon: <EventIcon />
    },
    // {
    //     title: 'Log v2',
    //     path: '/report/logevents',
    //     icon: <WorkIcon />
    // },
    {
        title: 'booking',
        path: '/booking',
        icon: <ChromeReaderModeIcon />
    },
    {
        title: 'Trips',
        path: '/report/trip',
        icon: <ModeOfTravelIcon />
    },
    {
        title: 'Stops',
        path: '/report/stop',
        icon: <WrongLocationIcon />
    },
    {
        title: 'Ev summary',
        path: '/report/ev-summary',
        icon: <SummarizeIcon />
    },
    {
        title: 'Chart',
        path: '/report/chart',
        icon: <ShowChartIcon />
    },
    {
        title: 'Log',
        path: '/report/log',
        icon: <ManageSearchRoundedIcon />
    },
    // {
    //     title: 'DLT',
    //     path: '/report/dlt',
    //     icon: <CloudDoneOutlinedIcon />
    // },
    {
        title: 'DLT certificate',
        path: '/report/dlt_cer',
        icon: <FindInPageRoundedIcon />
    },
    // {
    //     title: 'Replay',
    //     path: '/report/replay',
    //     icon: <EventRepeatIcon />
    // },
    {
        title: 'Replay',
        path: '/report/replayv2',
        icon: <EventRepeatIcon />
    },
    {
        title: 'Events',
        path: '/report/events',
        icon: <NotificationsActiveRoundedIcon />
    },
    {
        title: 'Work',
        path: '/report/work',
        icon: <WorkIcon />
    }
];

export default SidebarReportConfig;
