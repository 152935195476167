import React, { useState } from 'react'
import { Box, Collapse, Tooltip, TextField, Stack, Grid, Checkbox, Typography, Table, TableBody, TableHead, TableRow, TableCell, Button, IconButton, InputAdornment, Dialog, DialogContent, DialogTitle, DialogActions, Divider, Autocomplete } from '@mui/material'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import CloseIcon from '@mui/icons-material/Close';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import SearchIcon from '@mui/icons-material/Search';
import { DesktopDatePicker, TimePicker } from '@mui/lab';
import QrCodeIcon from '@mui/icons-material/QrCode';
// import QRCode from 'react-qr-code';
import QRCode from 'qrcode.react';

import html2canvas from 'html2canvas';



export default function DialogQrcode({ data }) {
    const [open, setOpen] = useState(false)
    const handleOpen = () => {
        setOpen(true)
    }
    const handleClose = () => {
        setOpen(false)
    }

    const handleDownload = async () => {
        const qrCodeElement = document.querySelector('.qr-code'); // Assuming CSS class
        const canvas = await html2canvas(qrCodeElement);
        const imageData = canvas.toDataURL('image/png');

        const link = document.createElement('a');
        link.href = imageData;
        link.download = `qr-code-${data.vehicle_id}.png`;
        link.click();
    };

    return (
        <Box>
            <Tooltip title="QR code ประจำรถ" placement="bottom" enterDelay={200} leaveDelay={5} >
                <IconButton size="small" onClick={handleOpen} color="inherit">
                    <QrCodeIcon fontSize="small" />
                </IconButton>
            </Tooltip>
            <Dialog
                open={open}
                maxWidth="md"
                // fullWidth={true}
                onClose={handleClose}
                slotProps={{
                    backdrop: {
                        style: {
                            // background: "#F8F8FF", opacity: 0.3
                            background: "#a7d8de", opacity: 0.2
                            // background: "#000", opacity: 0.1
                        }
                    }
                }}
            >
                <DialogTitle>QR Code</DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    <Box
                        className='qr-code'
                        sx={{ padding: "10px", backgroundColor: "white" }}
                    >
                        <QRCode
                            value={JSON.stringify({
                                "Act": {
                                    "IMG": {
                                        "type": "JPG"
                                    }
                                },
                                "eventlist": [
                                    {
                                        "DEVICE": {
                                            "vehicle_id": data.vehicle_id,
                                            "vehicle_chassis_no": data.vehicle_chassis_no
                                        }
                                    }
                                ]
                            })}
                            level="L" size={256} style={{ width: '256px', height: '256px' }}
                        />
                        <Box sx={{ marginTop: "5px", textAlign: "center" }}>
                            <Typography variant="h5" >
                                {data?.vehicle_id}
                            </Typography>
                        </Box>
                    </Box>

                </DialogContent >
                <DialogActions>
                    <Button onClick={handleDownload}>บันทึก</Button>
                </DialogActions>
            </Dialog >
        </Box >
    )
}
