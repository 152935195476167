import React, { Fragment, useState } from 'react'
import { Stack, IconButton, Tooltip, Typography, Dialog, DialogContent, DialogActions, DialogTitle, Box, Grid, Button, Backdrop, FormControlLabel, Checkbox, Table, TableHead, TableRow, TableBody, TableCell } from '@mui/material'
import axios from 'axios'
import { Api } from '../../components/api'
import EditDeviceCerDlt from './EditDeviceCerDlt'
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';
import SubtitlesOutlinedIcon from '@mui/icons-material/SubtitlesOutlined';
import DialogCerDlt from './DialogCerDlt'
import CloseIcon from '@mui/icons-material/Close';
import AddDeviceCerDlt from './AddDeviceCerDlt'
import { useSnackbar } from 'notistack'
import { fDateTo } from '../../utils/formatTime'
import DeleteIcon from '@mui/icons-material/Delete';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import HasPermission from '../../haspermission/HasPermission'

export default function DeviceCerDlt({ account, chassis, permissions, datadevice }) {
    const { cocode } = account
    const [open, setOpen] = useState(false)
    const [dltCers, setDltCers] = useState([])

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const handleSnackbar = ({ message, variant }) => {
        enqueueSnackbar(message,
            {
                autoHideDuration: 1000,
                anchorOrigin: {
                    horizontal: "right",
                    vertical: 'bottom'
                },
                variant: variant
            });
    }

    const handleSnackWithAction = ({ message, onHandle }) => {
        enqueueSnackbar(message, {
            anchorOrigin: {
                horizontal: "center",
                vertical: 'bottom'
            },
            variant: 'default',
            action: (key) => (
                <Fragment>
                    <Stack direction="row" spacing={1}>
                        <Button
                            size='small'
                            color="error"
                            variant="contained"
                            onClick={() => {
                                closeSnackbar(key)
                                onHandle()
                            }}
                        >
                            <CheckRoundedIcon fontSize="small" />
                        </Button>
                        <Button size='small' variant="contained" color="success" onClick={() => closeSnackbar(key)}>
                            <CloseRoundedIcon fontSize="small" />
                        </Button>
                    </Stack>
                </Fragment>
            )
        });
    };
    const getCer = () => {
        Api.get(`/v0/gps/certificatesByChassis?cocode=${cocode}&chassis=${chassis}`)
            .then(res => {
                setDltCers(res.data)
                setOpen(true)
            })
            .catch(err => {
                console.log('err', err)
                setDltCers([])
                setOpen(true)
            })
    }

    const getCerA = () => {
        Api.get(`/v0/gps/certificatesByChassis?cocode=${cocode}&chassis=${chassis}`)
            .then(res => {
                setDltCers(res.data)
            })
            .catch(err => {
                console.log('err', err)
                setDltCers([])
                setOpen(true)
            })
    }

    const onClickOpen = () => {
        if (chassis) {
            getCer()
        } else {
            handleSnackbar({ message: "กรุณาเพิ่มหมายเลขคัสซี", variant: "error" })
        }
    }
    const onClickClose = () => {
        setOpen(false)
    }

    const handleDel = (dltCer) => {
        const data = JSON.stringify(dltCer)
        Api.delete(`v0/gps/certificates`, { data: data })
            .then((res) => {
                handleSnackbar({ message: "ลบข้อมูลสำเร็จ", variant: "success" })
                getCerA()
                onClickClose()
            })
            .catch((err) => {
                handleSnackbar({ variant: 'error', message: "ลบข้อมูลไม่สำเร็จ" });
            })
    }
    return (
        <div>
            <Tooltip title="ใบรับรอง DLT / Cert DLT " placement="bottom" enterDelay={200} leaveDelay={5}>
                <IconButton size="small" color="inherit" onClick={onClickOpen}>
                    <SubtitlesOutlinedIcon fontSize="small" />
                </IconButton>
            </Tooltip>
            <Dialog
                // fullScreen
                fullWidth={true}
                maxWidth="md"
                open={open}
                onClose={onClickClose}
                slotProps={{
                    backdrop: {
                        style: {
                            background: "#a7d8de",
                            opacity: 0.2
                        }
                    }
                }}
            >
                <DialogTitle>
                    <Stack spacing={1} direction="row">
                        <Typography variant="h6">ข้อมูลใบรับรอง</Typography>
                    </Stack>
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={onClickClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>docno</TableCell>
                                <TableCell>customer_name</TableCell>
                                <TableCell>vehicle_id</TableCell>
                                <TableCell>unit_id</TableCell>
                                <TableCell>สถานะ</TableCell>
                                <TableCell>
                                    <Stack direction="row" justifyContent="end" spacing={1}>
                                        <HasPermission permissions={permissions} scope={["createcerdlt"]}>
                                            <AddDeviceCerDlt account={account} getCer={getCerA} datadevice={datadevice} />
                                        </HasPermission>
                                    </Stack>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                dltCers.map((cer, index) => {
                                    return (
                                        <TableRow key={index}>
                                            <TableCell>{cer.docno}</TableCell>
                                            <TableCell style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", maxWidth: "185px" }}>{cer.customer_name}</TableCell>
                                            <TableCell>{cer.vehicle_id}</TableCell>
                                            <TableCell style={{ whiteSpace: "nowrap", overflow: "auto", maxWidth: "185px" }}>{cer.unit_id}</TableCell>
                                            <TableCell>{cer.revokedate === null ? `ใช้งาน` : `ยกเลิก ${fDateTo(cer.revokedate, "dd/MM/yyyy")}`}</TableCell>
                                            <TableCell>
                                                <Stack spacing={1} direction="row" justifyContent="end">
                                                    <HasPermission permissions={permissions} scope={["updatecerdlt"]}>
                                                        <EditDeviceCerDlt cer={cer} getCer={getCerA} account={account} />
                                                    </HasPermission>
                                                    <HasPermission permissions={permissions} scope={["print"]}>
                                                        <DialogCerDlt cer={cer} />
                                                    </HasPermission>
                                                    <HasPermission permissions={permissions} scope={["deletecerdlt"]}>
                                                        <IconButton size="small" onClick={() => {
                                                            handleSnackWithAction({ message: "ต้องการที่จะลบ?", onHandle: () => { handleDel(cer) } })
                                                        }}>
                                                            <DeleteIcon fontSize="small" />
                                                        </IconButton>
                                                    </HasPermission>
                                                </Stack>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })
                            }
                        </TableBody>
                    </Table>
                </DialogContent>
            </Dialog>

        </div>
    )
}
