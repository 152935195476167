import React, { useEffect, useState } from 'react'
import { Box, Collapse, TextField, Badge, ButtonGroup, Stack, Grid, Checkbox, Typography, Paper, TableContainer, Table, TableBody, TableHead, TableRow, TableCell, Button, IconButton, InputAdornment, Dialog, DialogContent, DialogTitle, DialogActions, Divider, Autocomplete, Tooltip, MenuItem } from '@mui/material'
import axios from 'axios'
import DialogUpdateEvents from './DialogUpdateEvents'
import { fDateTo } from '../../utils/formatTime'
import { useNavigate } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
export default function DialogViewWork({ onClose, onCloseEsc, open, data, userId }) {
    const [openUpdate, setOpenWorkDate] = useState(false)
    const [dataUpdate, setDataUpdate] = useState({})
    const onCloseUpdate = () => {
        setOpenWorkDate(false)
        setDataUpdate({})
        onClose()
    }
    const onOpenUpdate = (work) => {
        setOpenWorkDate(true)
        setDataUpdate(work)
    }
    const navigate = useNavigate()

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const handleClickVariant = ({ message, variant }) => {
        enqueueSnackbar(message, { variant: variant, anchorOrigin: { vertical: 'bottom', horizontal: 'right' }, autoHideDuration: 1000 });

    };
    const handleSnackWithAction = ({ message, onHandle }) => {
        closeSnackbar()
        enqueueSnackbar(message, {
            anchorOrigin: {
                horizontal: "center",
                vertical: 'bottom'
            },
            variant: 'default',
            action: (key) => {
                return (
                    <React.Fragment>
                        <Stack direction="row" spacing={1}>
                            <Button
                                size='small'
                                color="error"
                                variant="contained"
                                onClick={() => {
                                    closeSnackbar(key)
                                    onHandle()
                                }}
                            >
                                <CheckRoundedIcon fontSize="small" />
                            </Button>
                            <Button size='small' variant="contained" color="success" onClick={() => closeSnackbar(key)}>
                                <CloseRoundedIcon fontSize="small" />
                            </Button>
                        </Stack>
                    </React.Fragment>
                )
            }
        });
    };


    const onHandleDelEvents = ({ id, type }) => {
        const data_req = {
            "booking_userid": userId,
            "id": `${id}`,
            "type": `${type}`,

        }
        axios.delete(`https://booking.sitgps.com/booking/event`, { data: data_req })
            .then(res => {
                handleClickVariant({ message: "ลบข้อมูลสำเร็จ", variant: "success" })
                onClose()
            })
            .catch(error => {
                console.log('error', error)
            })
    }


    return (
        <div>
            <Dialog onClose={onCloseEsc} open={open} maxWidth="lg" sx={{ zIndex: 1302 }}>
                <DialogTitle>งาน</DialogTitle>
                <DialogContent>
                    <DialogUpdateEvents userId={userId} open={openUpdate} onClose={onCloseUpdate} data={dataUpdate} />
                    <Box marginTop="8px">
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>ชื่อ</TableCell>
                                    <TableCell>วันที่เริ่ม</TableCell>
                                    <TableCell>เวลา</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    data.map((w, i) => {
                                        return (
                                            <TableRow key={`work-${i}`}

                                            >
                                                <TableCell sx={{ whiteSpace: "nowrap" }}>{`${w.event_name || w.company_name || w.booking_id}`}</TableCell>
                                                <TableCell sx={{ whiteSpace: "nowrap" }}>{fDateTo(w.date_start, "dd/MM/yyyy HH:mm")}</TableCell>
                                                <TableCell sx={{ whiteSpace: "nowrap" }}>{fDateTo(w.date_end, "dd/MM/yyyy HH:mm")}</TableCell>
                                                <TableCell>
                                                    {
                                                        w.id && (
                                                            <Stack spacing={1} direction="row">

                                                                <Button size="small"
                                                                    variant="contained"
                                                                    onClick={() => {
                                                                        onOpenUpdate(w)
                                                                    }}
                                                                >แก้ไข</Button>
                                                                <Button
                                                                    onClick={() => {
                                                                        handleSnackWithAction({
                                                                            message: "ต้องการลบข้อมูล",
                                                                            onHandle: () => onHandleDelEvents({ id: w.id, type: w.type })
                                                                        })
                                                                    }}
                                                                    variant="outlined" size="small" >ยกเลิก Event</Button>
                                                            </Stack>
                                                        )
                                                    }
                                                    {
                                                        w.booking_id && (
                                                            <Button size="small"
                                                                variant="contained"
                                                                onClick={() => {
                                                                    navigate(`../update/${w.booking_id}`)
                                                                }}
                                                            >แก้ไข</Button>
                                                        )
                                                    }
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })
                                }
                            </TableBody>
                        </Table>
                    </Box>
                </DialogContent>
            </Dialog>
        </div>
    )
}
