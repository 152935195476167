import { Backdrop, useMediaQuery, Grid, Box, IconButton, Button, ListItemText, Menu, MenuItem, Slider, TextField, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Autocomplete, Tooltip } from '@mui/material';
import { styled, useTheme } from '@mui/styles';
import PolylineUtil from 'polyline-encoded';
import React, { useEffect, useRef, useState } from 'react';
import { MapContainer, Polyline, TileLayer, ZoomControl, GeoJSON } from 'react-leaflet';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { Api, Apihistory, Apimongodb } from '../../components/api';
import ListTileLayer from '../../layouts/ListTileLayer';
import { fDateTo } from '../../utils/formatTime';
import { listTablesAtom, loadingAtom, markerAtom } from './markerAtom';
import MarkerRotatedWithLabel from './MarkerRotatedWithLabel';
import CircularProgress from '@mui/material/CircularProgress';
import BottomNavigationBar from '../../layouts/BottomNavigationBar';
import PlayCircleTwoToneIcon from '@mui/icons-material/PlayCircleTwoTone';
import PauseCircleFilledTwoToneIcon from '@mui/icons-material/PauseCircleFilledTwoTone';
import ReplayTwoToneIcon from '@mui/icons-material/ReplayTwoTone';
import { LoadingButton, LocalizationProvider, MobileDateTimePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { Form, FormikProvider, useFormik } from 'formik';
import { useLocation } from 'react-router-dom';
import { CSVLink } from 'react-csv';
import LayersIcon from '@mui/icons-material/Layers';
import { useSnackbar } from 'notistack';

import PlaceRoundedIcon from '@mui/icons-material/PlaceRounded';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';

import * as ReactDOMServer from 'react-dom/server';
import { TableVirtuoso } from 'react-virtuoso';
import HasPermission from '../../haspermission/HasPermission';

const BoxMainStyle = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  [theme.breakpoints.down('md')]: {
    display: "block",
    flexDirection: "column"
  },
}));

const BoxPanelStyle = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    display: "flex",
    flexDirection: "row"
  },

}));

const BoxFormStyle = styled(Box)(({ theme }) => ({
  padding: "10px",
  [theme.breakpoints.down('md')]: {
    width: "100vw"
  },

}));



export default function HistoryReplay({ userId, account, permissions }) {
  const [devices, setDevices] = useState([])
  const [loading, setLoading] = useState(true)
  const [defaultDevice, setDefaultDevice] = useState(null)
  const location = useLocation()

  useEffect(() => {
    Api.get(`/Tracking/DevicesSearch?userid=${userId}&search=*`).then((res) => {
      var devices_ = res.data.sort((a, b) => a.vehicle_id.localeCompare(b.vehicle_id));
      setDevices(devices_);
      if (location.state?.defaultValue !== undefined) {
        setDefaultDevice(devices_.find(d => d.device === location.state.defaultValue))
      }
      setLoading(loading => !loading)
    }
    ).catch(e => {
      console.log('error', e)
      setLoading(loading => !loading)
    })

  }, [userId])


  return (
    <>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      // onClick={() => { setLoading(false) }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {!loading && (
        <ReplayForm account={account} defaultDevice={defaultDevice} devices={devices} permissions={permissions} />
      )}
    </>
  )
}
function ReplayForm({ account, defaultDevice, devices, permissions }) {
  const [listTables, setListTables] = useRecoilState(listTablesAtom)
  const [markerSelect, setMarkerSelect] = useState(null)
  const [isGeoJson, setIsGeoJson] = useState(null)
  const [map, setMap] = useState(null);
  const refMapStyle = useRef(null);
  const [selectedMapStyle, setSelectedMapStyle] = useState(localStorage.getItem("selectedMapStyle") || "LongdoMap")
  const [listReplay, setListReplay] = useState([])
  const [loading, setLoading] = useState(false)
  const [move, setMove] = useState(false)
  // const MemoizedItem = useMemo(Item);
  const [viewPoi, setViewPoi] = useState(false)
  const [sliderValue, setSliderValue] = useState(0)


  const geoJsonLayerRef = useRef(null)

  useEffect(() => {
    const element = document.getElementById("tableRow" + sliderValue);
    if (markerSelect) {
      element?.classList.add("tableRow-active")
      // element.scrollIntoView({ block: "end" })
    }
    return () => {
      element?.classList?.remove("tableRow-active")
    }
  }, [sliderValue, markerSelect])


  useEffect(() => {
    if (markerSelect && move) {
      map.setView([markerSelect?.latitude, markerSelect?.longitude], map.getZoom(), {
        "animate": true,
        "duration": 1,
      })
    }
  }, [markerSelect, move])

  const selectedTime = new Date();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));


  const formik = useFormik({
    initialValues: {
      device: defaultDevice,
      dateStart: selectedTime.setHours(0, 0, 0),
      dateEnd: selectedTime.setHours(23, 59, 59),
    },
    // enableReinitialize: true,
    // validationSchema: ReplaySchema,
    onSubmit: (values, { setSubmitting }) => {
      setViewPoi(false)
      setLoading(true)
      if (listTables.length > 0) {
        setListTables([])
        setListReplay([])
        setMarkerSelect(null)
        setSliderValue(0)
        setIsGeoJson(null)
      }
      Apihistory.get('/tracking/history', {
        // Api.get('/tracking/history', {
        params: {
          device: values.device?.device,
          dateStart: fDateTo(values.dateStart, 'yyyy-MM-dd HH:mm:ss'),
          dateEnd: fDateTo(values.dateEnd, 'yyyy-MM-dd HH:mm:ss')
        },
        headers: {
          cname: "mn&&a500"
        }
      }).then((res) => {
        const { encode, gpslog } = res.data
        if (encode) {
          setLoading(false)
          setListTables(gpslog)
          const _encode = PolylineUtil.decode(encode)
          map.flyToBounds(_encode, { duration: 1, paddingTopLeft: [8, (isMobile ? 172 : 0) + 8] })
          setListReplay(_encode);
          setSubmitting()
        } else {
          handleSnackbar({ message: 'Not found', variant: 'error' })
          setLoading(false)
          setSubmitting()
        }
      }).catch((e) => {
        handleSnackbar({ message: 'Error please try again.', variant: 'error' })
        setLoading(false)
        setSubmitting()
      });
    }
  });


  const onGetPoi = () => {
    if (isGeoJson === null && listTables.length > 0) {
      const { c_coname } = account
      setLoading(true)
      const datalog = {
        "cname": c_coname,
        "gpslog": listTables
      }
      Apimongodb.post(`/Tracking/poi_tracking`, datalog)
        .then(res => {
          setViewPoi(true)
          setIsGeoJson(res.data)
          setLoading(false)
        })
        .catch(err => {
          setViewPoi(false)
          handleSnackbar({ message: "การดึงข้อมูล POI มีปัญหา", variant: "error" })
          setIsGeoJson(null)
          setLoading(false)
        })
    } else {
      if (isGeoJson !== null) {
        setViewPoi(true)
      }
    }
  }

  const { errors, touched, isSubmitting, values, setFieldValue, handleSubmit, getFieldProps } = formik;

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const handleSnackbar = ({ message, variant }) => {
    enqueueSnackbar(message,
      {
        autoHideDuration: 1000,
        anchorOrigin: {
          horizontal: "right",
          vertical: 'bottom'
        },
        variant: variant
      });
  }


  function onEachFeature(feature, layer) {
    if (feature.properties && feature.geometry.type === "Polygon") {
      const { name, fill, stroke } = feature.properties;
      layer.setStyle({ fillColor: fill, color: stroke })
      const popupContent = ReactDOMServer.renderToString(
        <Popup feature={feature} />
      );
      layer.bindPopup(popupContent);
      layer.bindTooltip(name, { direction: "top" });
    }
  }


  const Popup = ({ feature }) => {
    const { name } = feature.properties;
    return (
      <div style={{ width: "251px", height: "315px", overflow: "auto" }}>
        <Table style={{
          width: "100%",
          border: "1px solid #9b9b9b",
          borderCollapse: "collapse"
        }}>
          <TableBody>
            {Object.keys(feature.properties).map((innerAttr, index) => {
              return (
                <TableRow key={`Popup-${index}`}>
                  <TableCell
                    style={{
                      border: "1px solid #9b9b9b",
                      borderCollapse: "collapse",
                      borderRadius: "5px",
                      padding: "5px"
                    }}
                  >
                    {innerAttr}
                  </TableCell>
                  <TableCell
                    style={{
                      border: "1px solid #9b9b9b",
                      borderCollapse: "collapse",
                      padding: "5px"
                    }}
                  >
                    {(innerAttr === "time_in" || innerAttr === "time_out") ? fDateTo(feature.properties[innerAttr], "dd/MM/yyyy HH:mm:ss") : feature.properties[innerAttr]}
                  </TableCell>
                </TableRow>
              )
            })
            }
          </TableBody>
        </Table>
      </div >
    );
  };





  const sideReplaySize = localStorage.getItem("sideReplaySize") ? JSON.parse(localStorage.getItem("sideReplaySize")) : { x: 400, y: 0 }

  const [replaySize, setReplaySize] = useState(sideReplaySize);
  const handler = (mouseDownEvent) => {
    const startSize = replaySize;
    const startPosition = { x: mouseDownEvent.pageX, y: mouseDownEvent.pageY };
    function onMouseMove(mouseMoveEvent) {
      setReplaySize(currentSize => ({
        x: (startSize.x - startPosition.x + mouseMoveEvent.pageX) < 400 ? 400 : startSize.x - startPosition.x + mouseMoveEvent.pageX,
        y: startSize.y - startPosition.y + mouseMoveEvent.pageY
      }));
      localStorage.setItem("sideReplaySize", JSON.stringify({
        x: (startSize.x - startPosition.x + mouseMoveEvent.pageX) < 400 ? 400 : startSize.x - startPosition.x + mouseMoveEvent.pageX,
        y: startSize.y - startPosition.y + mouseMoveEvent.pageY
      }))
    }

    function onMouseUp() {
      document.body.removeEventListener("mousemove", onMouseMove);
    }

    document.body.addEventListener("mousemove", onMouseMove);
    document.body.addEventListener("mouseup", onMouseUp, { once: true });

  };


  return (
    <BoxMainStyle>
      <Box
        sx={(theme) => ({
          width: `${sideReplaySize.x}px`,
          [theme.breakpoints.down('md')]: {
            position: "relative",
            width: "calc(100vw)",
            zIndex: 999,
            backgroundColor: "white"
          },
        })}
      >
        <BoxPanelStyle>
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          <BoxFormStyle>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                  <Grid container spacing={1}>
                    <Grid item xs={4} md={12}>
                      <Autocomplete
                        id="device"
                        name="device"
                        options={devices}
                        // value={values.device}
                        defaultValue={values.device}
                        size="small"
                        getOptionLabel={(option) => option.vehicle_id}
                        isOptionEqualToValue={(option, value) => option.device === value.device}
                        fullWidth
                        renderOption={(props, item) => (
                          <li {...props} key={item.vehicle_id}>
                            <ListItemText>{item.vehicle_id}</ListItemText>
                          </li>
                        )}
                        filterOptions={(options, state) => {
                          return options.filter(opt => opt.vehicle_id.toUpperCase().includes(state.inputValue.toUpperCase()))
                        }}
                        onChange={(e, value) => {
                          setFieldValue("device", value);
                        }}

                        renderInput={(params) => (
                          <TextField
                            label="เลือกอุปกรณ์ GPS"
                            fullWidth
                            autoComplete="off"
                            name="device"
                            {...params}
                            {...getFieldProps('device')}
                            error={Boolean(touched.device && errors.device)}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={4} md={6}>
                      <MobileDateTimePicker
                        ampm={false}
                        disableFuture={true}
                        label="วันเวลาเริ่ม"
                        allowKeyboardControl={false}
                        value={values.dateStart}
                        inputFormat="dd/MM/yyyy HH:mm"
                        format="dd/MM/yyyy HH:mm"
                        onChange={(val) => {
                          setFieldValue('dateStart', val);
                        }}
                        renderInput={(params) => <TextField
                          size="small"
                          {...params}
                          {...getFieldProps('dateStart')}
                          fullWidth
                          placeholder="DD/MM/YYYY"
                          autoComplete="off"
                          error={Boolean(touched.dateStart && errors.dateStart)}
                          helperText={touched.dateStart && errors.dateStart}
                        />}
                      />
                    </Grid>
                    <Grid item xs={4} md={6}>
                      <MobileDateTimePicker
                        minDate={new Date(values.dateStart)}
                        ampm={false}
                        disableFuture={true}
                        label="ถึงวันเวลา"
                        allowKeyboardControl={false}
                        value={values.dateEnd}
                        inputFormat="dd/MM/yyyy HH:mm"
                        format="dd/MM/yyyy HH:mm"
                        onChange={(val) => {
                          setFieldValue('dateEnd', val);
                        }}
                        renderInput={(params) => <TextField
                          size="small"
                          {...params}
                          {...getFieldProps('dateEnd')}
                          fullWidth
                          placeholder="DD/MM/YYYY"
                          autoComplete="off"
                          error={Boolean(touched.dateEnd && errors.dateEnd)}
                          helperText={touched.dateEnd && errors.dateEnd} />}
                      />
                    </Grid>
                    <Grid item xs={6} md={12}>
                      <LoadingButton
                        fullWidth
                        size="medium"
                        type="submit"
                        variant="contained"
                        color="info"
                        loading={isSubmitting}
                      >
                        แสดง
                      </LoadingButton>
                    </Grid>
                    <Grid item xs={6} md={6}>
                      <HasPermission permissions={permissions} scope={["download"]}>
                        <MemoizedCsvButton listTables={listTables} />
                      </HasPermission>
                    </Grid>
                    <Grid item xs={6} md={6}>
                      <HasPermission permissions={permissions} scope={["download"]}>
                        <MemoizedCsvButtonPoi listTables={isGeoJson?.features} />
                      </HasPermission>
                    </Grid>
                  </Grid>
                </Form>
              </FormikProvider>
            </LocalizationProvider>
          </BoxFormStyle>
          <Box sx={(theme) => ({
            width: `${sideReplaySize.x}px`,
            height: "calc(100vh - 200px)",
            maxHeight: "calc(100vh - 200px)",
            overflowX: "auto",
            MozUserSelect: "none",
            WebkitUserSelect: "none",
            msUserSelect: "none",
            userSelect: "none",
            [theme.breakpoints.down('md')]: {
              position: "relative",
              width: "calc(100vw)",
              height: "calc(100vh - 90px)",
              maxHeight: "calc(100vh - 90px)",
              zIndex: 999,
              backgroundColor: "white"
            },
          })}>
            {
              viewPoi && (
                <TableVirtuoso
                  style={{ height: isMobile ? "calc(100vh - 120px)" : "calc(100vh - 260px)" }}
                  data={isGeoJson?.features ? isGeoJson?.features : []}
                  components={TableComponents}
                  fixedHeaderContent={() => (
                    <TableRow sx={{ backgroundColor: "white" }}>
                      <TableCell sx={{ whiteSpace: "nowrap", width: 60, textAlign: "left" }}>id</TableCell>
                      <TableCell sx={{ whiteSpace: "nowrap", width: 60, textAlign: "left" }}>name</TableCell>
                      <TableCell sx={{ whiteSpace: "nowrap", width: 60, textAlign: "left" }}>time_in</TableCell>
                      <TableCell sx={{ whiteSpace: "nowrap", width: 60, textAlign: "left" }}>time_out</TableCell>
                      <TableCell sx={{ whiteSpace: "nowrap", width: 60, textAlign: "left" }}>time</TableCell>
                    </TableRow>
                  )}
                  itemContent={(index, value) => (
                    <React.Fragment
                      key={"tableRow" + index}
                    >
                      <TableCell sx={{ whiteSpace: "nowrap", cursor: "pointer" }}
                        onClick={() => {
                          if (value?.geometry.type === "Polygon") {
                            map.flyToBounds(value?.geometry.coordinates[0].map(v => {
                              return [v[1], v[0]]
                            }), { duration: 1 })
                          }
                        }}
                      >{value?.properties.id}</TableCell>
                      <TableCell sx={{ whiteSpace: "nowrap", cursor: "pointer" }}
                        onClick={() => {
                          if (value?.geometry.type === "Polygon") {
                            map.flyToBounds(value?.geometry.coordinates[0].map(v => {
                              return [v[1], v[0]]
                            }), { duration: 1 })
                          }
                        }}
                      >{value?.properties.name}</TableCell>
                      <TableCell sx={{ whiteSpace: "nowrap", cursor: "pointer" }}
                        onClick={() => {
                          if (value?.geometry.type === "Polygon") {
                            map.flyToBounds(value?.geometry.coordinates[0].map(v => {
                              return [v[1], v[0]]
                            }), { duration: 1 })
                          }
                        }}
                      >{value?.properties.time_in && fDateTo(value.properties.time_in, "dd/MM/yyyy HH:mm")}</TableCell>
                      <TableCell sx={{ whiteSpace: "nowrap", cursor: "pointer" }}
                        onClick={() => {
                          if (value?.geometry.type === "Polygon") {
                            map.flyToBounds(value?.geometry.coordinates[0].map(v => {
                              return [v[1], v[0]]
                            }), { duration: 1 })
                          }
                        }}
                      >{value?.properties.time_out && fDateTo(value.properties.time_out, "dd/MM/yyyy HH:mm")}</TableCell>
                      <TableCell sx={{ whiteSpace: "nowrap", cursor: "pointer" }}
                        onClick={() => {
                          if (value?.geometry.type === "Polygon") {
                            map.flyToBounds(value?.geometry.coordinates[0].map(v => {
                              return [v[1], v[0]]
                            }), { duration: 1 })
                          }
                        }}
                      >{value?.properties.time}</TableCell>
                    </React.Fragment>
                  )}
                />
              )
            }
            {
              !viewPoi && (
                <TableVirtuoso
                  style={{ height: isMobile ? "calc(100vh - 120px)" : "calc(100vh - 260px)" }}
                  data={listTables}
                  components={TableComponents}
                  fixedHeaderContent={() => (
                    <TableRow sx={{ backgroundColor: "white" }}>
                      <TableCell sx={{ whiteSpace: "nowrap", width: 60, textAlign: "left" }}>datetime_gps</TableCell>
                      <TableCell sx={{ whiteSpace: "nowrap", width: 60, textAlign: "left" }}>speed</TableCell>
                      <TableCell sx={{ whiteSpace: "nowrap", width: 60, textAlign: "left" }}>odometer</TableCell>
                    </TableRow>
                  )}
                  itemContent={(index, value) => (
                    <React.Fragment
                      key={"tableRow" + index}
                    >
                      <TableCell sx={{ whiteSpace: "nowrap", cursor: "pointer" }}
                        onClick={() => {
                          setMarkerSelect(value)
                          setMove(true)
                          setSliderValue(index)
                        }}
                      >{value?.datetime_gps && fDateTo(value.datetime_gps, "dd/MM/yyyy HH:mm")}</TableCell>
                      <TableCell sx={{ whiteSpace: "nowrap", cursor: "pointer" }}
                        onClick={() => {
                          setMarkerSelect(value)
                          setMove(true)
                          setSliderValue(index)
                        }}
                      >{value?.speed}</TableCell>
                      <TableCell sx={{ whiteSpace: "nowrap", cursor: "pointer" }}
                        onClick={() => {
                          setMarkerSelect(value)
                          setMove(true)
                          setSliderValue(index)
                        }}
                      >{value?.odometer}</TableCell>
                    </React.Fragment>
                  )}
                />
              )
            }
          </Box>
          {/* <MemoizedItem setMarkerSelect={setMarkerSelect} setMove={setMove} setSliderValue={setSliderValue} /> */}
        </BoxPanelStyle>
        <Paper sx={{ position: 'fixed', width: sideReplaySize.x, bottom: 0, left: 0, right: 0, zIndex: 2 }} elevation={3}>
          <BottomNavigationBar ative="report" />
        </Paper>
      </Box>
      <Box sx={{ height: "100vh", width: "4px", backgroundColor: "whitesmoke" }}>
        <div id="container" style={{ width: 4, height: "100vh", zIndex: 999, backgroundColor: "whitesmoke", cursor: "col-resize" }} onMouseDown={handler} />
      </Box>
      <Box
        sx={(theme) => ({
          width: `calc(100vw - ${sideReplaySize.x}px)`,
          position: "relative",
          height: "100vh",
          [theme.breakpoints.down('md')]: {
            width: "calc(100vw)",
            height: "calc(100vh - 160px)",
          },
        })} >
        <MapContainerStyle
          whenCreated={m => {
            setMap(m)
          }}
          center={center}
          zoom={zoom}
          minZoom={3}
          zoomControl={false}
          scrollWheelZoom={true}
          doubleClickZoom={false}
          attributionControl={false}
        >
          <TileLayer ref={refMapStyle}
            url={ListTileLayer.find(value => value.id === selectedMapStyle).url}
            maxNativeZoom={19}
            maxZoom={22}
          />
          <ZoomControl position='topright' />

          {
            viewPoi && (
              <GeoJSON style={{ zIndex: 999 }} data={isGeoJson} onEachFeature={onEachFeature} key={JSON.stringify(isGeoJson)} />
            )
          }
          {
            !viewPoi && (
              <Polyline positions={listReplay} weight={3} />
            )
          }
          {
            markerSelect && (
              <MarkerRotatedWithLabel
                position={[markerSelect?.latitude, markerSelect?.longitude]}
                rotationAngle={markerSelect?.bearing}
                rotationOrigin="center"
              />
            )
          }

        </MapContainerStyle>
        <PlayBack listTables={listTables} setMarkerSelect={setMarkerSelect} markerSelect={markerSelect} setMove={setMove}
          sliderValue={sliderValue} setSliderValue={setSliderValue}
        />
        <MemoizedLayerControl refMapStyle={refMapStyle} selectedMapStyle={selectedMapStyle} setSelectedMapStyle={setSelectedMapStyle} />
        {
          markerSelect && (
            <MarkerLabelSelect markerSelect={markerSelect} />
          )
        }
        <ViewPoiControl>
          {
            viewPoi && (
              <Tooltip title="กดเพื่อปิดการแสดง POI" >
                <IconButton edge="end" size="small"
                  style={{ backgroundColor: 'white', borderRadius: 5, }}
                  onClick={() => {
                    setViewPoi(false)
                  }}
                >
                  <PlaceRoundedIcon fontSize="inherit" />
                </IconButton>
              </Tooltip>

            )
          }
          {
            !viewPoi && (
              <Tooltip title="กดเพื่อเปิดการแสดง POI" >
                <IconButton edge="end" size="small"
                  style={{ backgroundColor: 'white', borderRadius: 5, }}
                  onClick={() => {
                    onGetPoi()
                  }}
                >
                  <PlaceOutlinedIcon fontSize="inherit" />
                </IconButton>
              </Tooltip>
            )
          }
        </ViewPoiControl>
      </Box>
    </BoxMainStyle >
  );
}





const MapContainerStyle = styled(MapContainer)(({ theme }) => ({
  height: '100%',
  // width: "100%",
  MozUserSelect: "none",
  WebkitUserSelect: "none",
  msUserSelect: "none",
  userSelect: "none",
  zIndex: 1,
  [theme.breakpoints.down('md')]: {
    width: "100vw",
  },
}));
const center = [13.839660198254604, 100.63469639884744];
const zoom = 6;

const PlayBackStyle = styled(Box)(({ theme }) => ({
  position: "absolute",
  left: 10,
  right: 10,
  bottom: 10,
  backgroundColor: "transparent",
  zIndex: 1,
  [theme.breakpoints.down('md')]: {
    bottom: 10,
  },
}));


const PlayBack = ({ listTables, setMarkerSelect, setMove, sliderValue, setSliderValue, markerSelect }) => {

  const itemLength = listTables.length

  const [play, setPlay] = useState(false)
  const [duration, setDuration] = useState(1000);
  const [durationSpeed, setDurationSpeed] = useState('1x');

  useEffect(() => {
    var intervalId
    if (play && sliderValue < itemLength - 1) {
      intervalId = setInterval(() => {
        setMove(false)
        const isReplay = listTables[sliderValue + 1]
        setMarkerSelect(isReplay)
        setSliderValue(sliderValue + 1);
        // const element = document.getElementById("tableRow" + (sliderValue + 1));
        // element?.scrollIntoView({ block: "end" })
      }, duration);
    }
    return () => clearInterval(intervalId);
  }, [play, sliderValue, listTables]);



  const stopNplay = (p) => {
    setPlay(p)
    if (p) {
      if (markerSelect === null) {
        // const element = document.getElementById("tableRow" + sliderValue);
        // element.scrollIntoView({ block: "end" })
        setMove(false)
        const isReplay = listTables[sliderValue]
        setMarkerSelect(isReplay)
        setSliderValue(sliderValue);
      } else {
        // const element = document.getElementById("tableRow" + (sliderValue + 1));
        // element.scrollIntoView({ block: "end" })
        setMove(false)
        const isReplay = listTables[sliderValue + 1]
        setMarkerSelect(isReplay)
        setSliderValue(sliderValue + 1);
      }
    }
  }



  return (
    <PlayBackStyle>
      {
        itemLength !== 0 && (
          <Box sx={{
            height: "40px",
            backgroundColor: "#333333",
            borderRadius: "10px",
            display: "flex",
            flexDirection: "row",
            color: "white",
            padding: "5px",
            position: "relative"
          }}>
            <Box sx={{
              display: "flex",
              alignItems: "center",
            }}>
              {
                itemLength - 1 === sliderValue ?
                  <IconButton
                    size="small"
                    onClick={() => {
                      // const element = document.getElementById("tableRow" + 0);
                      // element.scrollIntoView({ block: "end" })
                      setSliderValue(0)
                    }}
                  >
                    <ReplayTwoToneIcon />
                  </IconButton>
                  :
                  play ?
                    <IconButton
                      size="small"
                      onClick={
                        () => {
                          stopNplay(false)
                        }
                      }
                    >
                      <PauseCircleFilledTwoToneIcon />
                    </IconButton>
                    :
                    <IconButton
                      size="small"
                      onClick={
                        () => {
                          stopNplay(true)
                        }
                      }
                    >
                      <PlayCircleTwoToneIcon />
                    </IconButton>

              }
            </Box>
            <Box sx={{
              display: "flex",
              alignItems: "center",
              marginRight: "10px"
            }}>
              <IconButton
                size="small"
                onClick={
                  () => {
                    if (duration === 1000) {
                      setDuration(500)
                      setDurationSpeed('2x')
                    }
                    if (duration === 500) {
                      setDuration(250)
                      setDurationSpeed('4x')

                    }
                    if (duration === 250) {
                      setDuration(125)
                      setDurationSpeed('8x')
                    }
                    if (duration === 125) {
                      setDuration(1000)
                      setDurationSpeed('1x')
                    }
                  }
                }
              >
                <span style={{
                  fontSize: '1rem',
                  fontWeight: '300'
                }}>
                  {
                    durationSpeed
                  }
                </span>
              </IconButton>
            </Box>
            <Box sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginRight: "10px"
            }}>
              <Slider
                size="small"
                max={itemLength - 1}
                aria-label="Temperature"
                defaultValue={0}
                value={sliderValue}
                valueLabelDisplay="auto"
                valueLabelFormat={(value) => {
                  return fDateTo(listTables[value].datetime_gps, "dd/MM/yyyy HH:mm:ss")
                }}
                onChange={(_, value) => {
                  if (value < itemLength) {
                    // const element = document.getElementById("tableRow" + value);
                    // element.scrollIntoView({ block: "end" })
                    setSliderValue(value)
                    setMarkerSelect(listTables[value])
                    setMove(false)
                  }
                }}
                color="secondary"
              />
            </Box>
          </Box>
        )
      }
    </PlayBackStyle >
  )
}

const BoxLabelRow = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between"
}));

const CsvButton = ({ listTables }) => {
  // console.log('CsvButton')
  return (
    <>
      {
        listTables.length > 0 && (
          <CSVLink style={{
            textDecoration: "none",
          }} filename={`data_log.csv`} data={
            listTables.map((value, index) => {
              const {
                device,
                datetime,
                datetime_gps,
                latitude,
                longitude,
                speed,
                bearing,
                input,
                ad0,
                ad1,
                ad2,
                ad3,
                ad4,
                gps,
                gsm,
                driver,
                status,
                odometer,
                engine_status

              } = value;
              return {
                device: `=""${device}""`,
                datetime: `=""${fDateTo(new Date(datetime), 'dd/MM/yyyy HH:mm:ss')}""`,
                datetime_gps: `=""${fDateTo(new Date(datetime_gps), 'dd/MM/yyyy HH:mm:ss')}""`,
                latitude: `=""${latitude}""`,
                longitude: `=""${longitude}""`,
                speed: `=""${speed}""`,
                bearing: `=""${bearing}""`,
                input: `=""${input}""`,
                ad0: `=""${ad0}""`,
                ad1: `=""${ad1}""`,
                ad2: `=""${ad2}""`,
                ad3: `=""${ad3}""`,
                ad4: `=""${ad4}""`,
                gps: `=""${gps}""`,
                gsm: `=""${gsm}""`,
                driver: `=""${driver}""`,
                status: `=""${status}""`,
                odometer: `=""${odometer}""`,
                engine_status: `=""${engine_status}""`,
              }
            }
            )
          }  >
            <Button fullWidth variant="contained" >Download log</Button>
          </CSVLink>
        )
      }
    </>
  )
}
const MemoizedCsvButton = React.memo(CsvButton);

const CsvButtonPoi = ({ listTables = [] }) => {
  // console.log('CsvButton')
  return (
    <>
      {
        listTables.length > 1 && (
          <CSVLink style={{
            textDecoration: "none",
          }} filename={`data_log_poi.csv`} data={
            listTables.map((value, index) => {
              const {
                id,
                name,
                time_in,
                time_out,
                time
              } = value?.properties;
              return {
                id: `=""${id}""`,
                name: `=""${name}""`,
                datetime: `=""${time_in && fDateTo(new Date(time_in), 'dd/MM/yyyy HH:mm:ss')}""`,
                datetime_gps: `=""${time_out && fDateTo(new Date(time_out), 'dd/MM/yyyy HH:mm:ss')}""`,
                time: `=""${time}""`
              }
            }
            )
          }  >
            <Button fullWidth variant="contained" >Download poi</Button>
          </CSVLink>
        )
      }
    </>
  )
}
const MemoizedCsvButtonPoi = React.memo(CsvButtonPoi);


const LayerControler = ({ refMapStyle, setSelectedMapStyle, selectedMapStyle }) => {
  const refDropDownMapStyle = useRef(null);
  const [openDropDown, setDropDown] = useState(false);

  const handleOpenDropDown = () => {
    setDropDown(!openDropDown);
  };

  return (
    <LayerControlStyle
      ref={refDropDownMapStyle}
    >
      <IconButton edge="end" size="small"
        style={{ backgroundColor: 'white', borderRadius: 5, }}
        onClick={() => {
          handleOpenDropDown()
        }}
      >
        <LayersIcon fontSize="inherit" />
      </IconButton>
      <Menu
        open={openDropDown}
        anchorEl={refDropDownMapStyle.current}
        onClose={() => handleOpenDropDown()}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' }
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Menu
          open={openDropDown}
          anchorEl={refDropDownMapStyle.current}
          onClose={() => handleOpenDropDown()}
          PaperProps={{
            sx: { width: 200, maxWidth: '100%' }
          }}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          {
            ListTileLayer.map((value, index) =>
              <MenuItem
                sx={{ color: 'text.default', fontWeight: selectedMapStyle === value.id ? 600 : 300 }}
                onClick={
                  () => {
                    handleOpenDropDown()
                    if (refMapStyle.current) {
                      refMapStyle.current.setUrl(value.url);
                      setSelectedMapStyle(value.id)
                      localStorage.setItem('selectedMapStyle', value.id)
                    }
                  }
                }
                key={index}
              >
                {
                  value.name
                }
              </MenuItem>
            )
          }
        </Menu>
      </Menu>
    </LayerControlStyle>
  )
}
const MemoizedLayerControl = React.memo(LayerControler);

const ViewPoiControl = styled(Box)(({ theme }) => ({
  position: 'absolute',
  backdropFilter: 'blur(10px)',
  justifyContent: 'center',
  border: "2px solid rgba(0,0,0,0.2)",
  borderRadius: 5,
  top: 10,
  left: 10,
  width: 32,
  height: 32,
  zIndex: 2,
}));

const LayerControlStyle = styled(Box)(({ theme }) => ({
  position: 'absolute',
  backdropFilter: 'blur(10px)',
  justifyContent: 'center',
  border: "2px solid rgba(0,0,0,0.2)",
  borderRadius: 5,
  top: 90,
  right: 10,
  width: 32,
  height: 32,
  zIndex: 2,
}));

const MarkerLabelSelect = ({ markerSelect }) => {
  return (
    <MarkerLabelStyle>
      <BoxLabelRow>
        <Box>วันที่ : </Box>
        <Box>
          {fDateTo(markerSelect?.datetime_gps, "dd-MM-yyyy HH:mm:ss")}
        </Box>
      </BoxLabelRow>
      <BoxLabelRow>
        <Box>ความเร็ว : </Box>
        <Box>
          {markerSelect?.speed}
        </Box>
      </BoxLabelRow>
      <BoxLabelRow>
        <Box>driver : </Box>
        <Box>
          {markerSelect?.driver}
        </Box>
      </BoxLabelRow>
      <BoxLabelRow>
        <Box>status : </Box>
        <Box>
          {markerSelect?.status}
        </Box>
      </BoxLabelRow>
      <BoxLabelRow>
        <Box>odometer : </Box>
        <Box>
          {markerSelect?.odometer}
        </Box>
      </BoxLabelRow>
      <BoxLabelRow>
        <Box>engine_status : </Box>
        <Box>
          {markerSelect?.engine_status}
        </Box>
      </BoxLabelRow>
      <BoxLabelRow>
        <Box>ad0 : </Box>
        <Box>
          {markerSelect?.ad0}
        </Box>
      </BoxLabelRow>
      <BoxLabelRow>
        <Box>ad1 : </Box>
        <Box>
          {markerSelect?.ad1}
        </Box>
      </BoxLabelRow>
      <BoxLabelRow>
        <Box>ad2 : </Box>
        <Box>
          {markerSelect?.ad2}
        </Box>
      </BoxLabelRow>
      <BoxLabelRow>
        <Box>ad3 : </Box>
        <Box>
          {markerSelect?.ad3}
        </Box>
      </BoxLabelRow>
      <BoxLabelRow>
        <Box>ad4 : </Box>
        <Box>
          {markerSelect?.ad4}
        </Box>
      </BoxLabelRow>
      <BoxLabelRow>
        <Box>gps : </Box>
        <Box>
          {markerSelect?.gps}
        </Box>
      </BoxLabelRow>
      <BoxLabelRow>
        <Box>gsm : </Box>
        <Box>
          {markerSelect?.gsm}
        </Box>
      </BoxLabelRow>
    </MarkerLabelStyle>
  )
}


const MarkerLabelStyle = styled(Box)(({ theme }) => ({
  position: "absolute",
  right: "50px",
  top: "10px",
  [theme.breakpoints.up('md')]: {
    width: "250px",
    // height: "172px",
  },
  [theme.breakpoints.down('md')]: {
    left: "50px",
    height: "172px",
  },
  overflow: "auto",
  fontSize: "16px",
  borderColor: "white",
  borderWidth: "10px",
  borderStyle: "solid",
  borderRadius: "10px",
  zIndex: 2,
  backgroundColor: "white"
}));

const DataTableStyle = styled(Paper)(({ theme }) => ({
  display: "unset",
  width: '100%',
  overflow: 'hidden',
  [theme.breakpoints.down('md')]: {
    display: "none"
  },
}));

const TableComponents = {
  Scroller: React.forwardRef((props, ref) => <TableContainer component={Paper} {...props} ref={ref} />),
  Table: (props) => <Table {...props} style={{ borderCollapse: 'separate' }} />,
  TableHead: TableHead,
  TableRow: TableRow,
  TableBody: React.forwardRef((props, ref) => <TableBody {...props} ref={ref} />),
}