import React, { useEffect, useState } from 'react';
import { Box, Collapse, TextField, Badge, MenuItem, ButtonGroup, Stack, Grid, Checkbox, Typography, Paper, TableContainer, Table, TableBody, TableHead, TableRow, TableCell, Button, IconButton, InputAdornment, Dialog, DialogContent, DialogTitle, DialogActions, Divider, Autocomplete, CircularProgress, Backdrop, Select } from '@mui/material'
import { fDateTo } from '../../../utils/formatTime';
import _, { values } from 'lodash';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

import CloseIcon from '@mui/icons-material/Close';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import TableOverViewCars from './TableOverViewCars';
import SearchIcon from '@mui/icons-material/Search';

import ArrowCircleLeftTwoToneIcon from '@mui/icons-material/ArrowCircleLeftTwoTone';
import ArrowCircleRightTwoToneIcon from '@mui/icons-material/ArrowCircleRightTwoTone';
const OverviewCars = ({ onHandle, filter = [], userId }) => {
  const [open, setOpen] = useState(false)



  const [page, setPage] = useState(1)
  const [pageMax, setPageMax] = useState(1)
  const [initialData, setInitialData] = useState([])
  const [loading, setloading] = useState(false)
  const [year, setYear] = useState(new Date().getFullYear())
  const [month, setMonth] = useState((new Date().getMonth() + 1).toString().padStart(2, "0"))
  const [search, setSearch] = useState("")
  const [carFilter, setCarFilter] = useState(null)
  const [typeCar, setTypeCar] = useState([])

  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  useEffect(() => {
    if (open) {
      if (filter.length > 0 && (filter[0]?.color !== "" && filter[0]?.seat !== "" && filter[0]?.fuel !== "")) {
        setCarFilter(filter[0])
        setTypeCar(filter)
        setloading(false)
      } else {
        axios.get(`https://booking.sitgps.com/booking/get_type_car`)
          .then(res => {
            const data = res.data
            setCarFilter(data[0])
            setTypeCar(data)
            setloading(false)
          })
          .catch(error => {
            setloading(false)
          })
      }
    }
  }, [open, filter])

  useEffect(() => {
    if (carFilter !== null) {
      setloading(true)
      const url = `https://booking.sitgps.com/booking/get_work_car?page=${page}&size=30&date=${year}-${month}&color=${carFilter.color}&seat=${carFilter.seat}&fuel=${carFilter.fuel}`
      axios.get(url)
        .then(res => {
          const iFllter = res.data.data
          const datatoset = iFllter.map(d => {
            const bookings = d.booking || []
            const events = d.event || []
            const databookings = bookings.map(v => {
              return {
                ...v,
                "date_start": (v.trip_start_datetime.toString()).replaceAll("T", " ").replaceAll(".000Z", ""),
                "date_end": (v.trip_end_datetime.toString()).replaceAll("T", " ").replaceAll(".000Z", ""),
              }
            })
            const dataevents = events.map(v => {
              return {
                ...v,
                "date_start": (v.date_start.toString()).replaceAll("T", " ").replaceAll(".000Z", ""),
                "date_end": (v.date_end.toString()).replaceAll("T", " ").replaceAll(".000Z", ""),
              }
            })
            return {
              "vehicle_id": d.vehicle_id,
              "color": d.color,
              "seat": d.seat,
              "size": d.size,
              "fuel": d.fuel,
              "data": databookings.concat(dataevents)
            }
          })
          setInitialData(datatoset)
          setPageMax(res.data.pagesize)
          setSearch("")
          setloading(false)
        })
        .catch(error => {
          setloading(false)
        })
    }
  }, [page, month, carFilter])


  const handleEventClick = (event) => {
    onHandle(event)
    handleClose()
  };

  const calculateMaxDate = () => {
    const lastDayOfMonth = new Date(year, month, 0).getDate();
    return lastDayOfMonth
  };

  const onSearch = (e) => {
    setSearch(e.target.value)
  }

  const initialdataFillter = initialData.filter((c, i) => c.vehicle_id.toLowerCase().includes(search.toLowerCase()))



  return (
    <Box>
      <IconButton onClick={handleOpen} size="small"><SearchIcon fontSize="inherit" /></IconButton>
      <Dialog
        open={open}
        fullScreen
        fullWidth={true}
        onClose={handleClose}
        slotProps={{
          backdrop: {
            style: {
              // background: "#F8F8FF", opacity: 0.3
              background: "#a7d8de", opacity: 0.2,
              position: "relative"
              // background: "#000", opacity: 0.1
            }
          }
        }}
      >
        <DialogTitle>เลือกรถ</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => { setOpen(false) }}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Box sx={{ margin: "10px" }}>
            <Box>
              <Stack direction="row" spacing={1}>
                <TextField size="small" label="ค้นหา" onChange={onSearch} value={search} />
                <TextField size="small"
                  type="month"
                  value={`${year}-${(month)}`}
                  onChange={(e) => {
                    const d = e.target.value
                    const yearNmonth = d.split("-")
                    setYear(yearNmonth[0])
                    setMonth(yearNmonth[1])
                    setPage(1)
                  }} />
              </Stack>
            </Box>
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
              <TableContainer sx={{ maxHeight: "calc(100vh - 70px)" }}>
                <Table stickyHeader size="small" style={{ border: "none", userSelect: "none" }} >
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ position: 'sticky', whiteSpace: "nowrap", left: 0, zIndex: 1302, textAlign: "center" }}> {"ทะเบียน"}</TableCell>
                      <TableCell style={{ position: 'sticky', whiteSpace: "nowrap", left: 0, zIndex: 1302, textAlign: "center" }} colSpan={calculateMaxDate()} >
                        {`${month}/${year}`}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ position: 'sticky', whiteSpace: "nowrap", left: 0, zIndex: 1303, backgroundColor: "white" }}>
                        <Stack spacing={0.2} direction="row" justifyContent="space-between">
                          <IconButton
                            size="small"
                            onClick={() => {
                              setPage(p => { return p - 1 })
                            }}
                            disabled={page === 1}
                          >
                            <ArrowCircleLeftTwoToneIcon fontSize="small" />
                          </IconButton>
                          <Select select value={carFilter} size="small"
                            onChange={(e) => {
                              setCarFilter(e.target.value)
                              setPage(1)
                            }}
                            sx={{
                              fontSize: "16px"
                            }}
                            MenuProps={{
                              sx: { zIndex: "1400" },
                            }}
                          >
                            {
                              typeCar.map((t, i) => {

                                return (
                                  <MenuItem sx={{ "&.MuiMenuItem": { zIndex: 1400 } }} id={`typeCar-${i}`} value={t}>
                                    {`${t.color}/${t.seat}/${t.fuel}`}
                                  </MenuItem>
                                )
                              })
                            }
                          </Select>
                          <IconButton
                            onClick={() => {
                              setPage(p => { return p + 1 })
                            }}
                            disabled={page === pageMax}
                            size="small"
                          >
                            <ArrowCircleRightTwoToneIcon fontSize="small" />
                          </IconButton>
                        </Stack>
                      </TableCell>
                      {
                        [...Array(calculateMaxDate())].map((value, index) => {
                          return (
                            <TableCell key={`Table-TableCell-${index}`} style={{ position: 'sticky', whiteSpace: "nowrap", left: 0, zIndex: 1302 }}>{`${(index + 1).toString().padStart(2, "0")}`}</TableCell>
                          )
                        })
                      }
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableOverViewCars userId={userId} open={open} dataFillter={initialdataFillter} handleEventClick={handleEventClick} maxDate={calculateMaxDate()} year={year} month={month} />
                  </TableBody>
                </Table>
              </TableContainer >
            </Paper >
          </Box >
        </DialogContent >
        <DialogActions>
        </DialogActions>
      </Dialog >
    </Box >
  );
};

export default OverviewCars;
