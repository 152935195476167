import React, { useEffect, useState } from 'react'
import { Box, Collapse, TextField, Stack, Grid, Checkbox, Typography, Paper, TableContainer, Table, TableBody, TableHead, TableRow, TableCell, Button, IconButton, InputAdornment, Dialog, DialogContent, DialogTitle, DialogActions, Divider, Autocomplete, Menu, MenuItem } from '@mui/material'

import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { useSnackbar } from 'notistack';
import axios from 'axios';




const initial = {
    "color": "",
    "seat": "",
    "fuel": ""
}

export default function TypeCarsDialog({ disabled, onHandle }) {
    const [formState, setFormState] = useState(initial)
    const [carsTypes, setCarTypes] = useState([])
    const [open, setOpen] = useState(false)

    const handleOpen = () => {
        setOpen(true)
    }
    const handleClose = () => {
        setOpen(false)
        setFormState(initial)
    }
    const handleSave = () => {
        setOpen(false)
        onHandle({ ...formState })
        setFormState(initial)
    }
    const handleChange = (e) => {
        const { value } = e.target
        setFormState(JSON.parse(value))
    }

    useEffect(() => {
        if (open) {
            axios.get(`https://booking.sitgps.com/booking/get_type_car`)
                .then(res => {
                    const data = res.data
                    setCarTypes(data)
                })
                .catch(error => {
                    // setloading(false)
                })
        }

    }, [open])

    return (
        <Box>
            <IconButton disabled={disabled} onClick={handleOpen} size="small"><SearchIcon fontSize="inherit" /></IconButton>
            <Dialog
                open={open}
                maxWidth="sm"
                fullWidth={true}
                onClose={handleClose}
                slotProps={{
                    backdrop: {
                        style: {
                            // background: "#F8F8FF", opacity: 0.3
                            background: "#a7d8de", opacity: 0.2
                            // background: "#000", opacity: 0.1
                        }
                    }
                }}
            >
                <DialogTitle>ประเภทรถ</DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={() => { setOpen(false) }}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    <Box component="form" >
                        <Stack >
                            <Grid container spacing={1} >
                                <Grid item xs={12}>
                                    <TextField fullWidth size="small" select value={JSON.stringify(formState)} onChange={handleChange} >
                                        <MenuItem value={JSON.stringify(initial)}>{`กรุณาเลือก`}</MenuItem>
                                        {
                                            carsTypes.map((c, i) => {
                                                return (
                                                    <MenuItem value={JSON.stringify(c)}>{`${(c)?.color}/${(c)?.seat}/${(c)?.fuel}`}</MenuItem>

                                                )
                                            })
                                        }
                                    </TextField>
                                </Grid>
                            </Grid>
                        </Stack>
                    </Box>
                </DialogContent >
                <DialogActions>
                    <Button onClick={handleSave}>บันทึก</Button>
                </DialogActions>
            </Dialog >
        </Box >
    )
}