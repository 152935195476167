import React, { useEffect, useState } from 'react'
import { Box, MenuItem, Collapse, TextField, Stack, Switch, Grid, Checkbox, Typography, Paper, TableContainer, Table, TableBody, TableHead, TableRow, TableCell, Button, IconButton, InputAdornment, Dialog, DialogContent, DialogTitle, DialogActions, Divider, Autocomplete } from '@mui/material'

import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import CloseIcon from '@mui/icons-material/Close';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { useSnackbar } from 'notistack';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { Api } from '../../../components/api';
import SettingsApplicationsRoundedIcon from '@mui/icons-material/SettingsApplicationsRounded';
import AddBoxRoundedIcon from '@mui/icons-material/AddBoxRounded';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import _ from 'lodash';

export default function ButtonDialogUpdateCustomer({ dataAccount, data, onHandleGet, dataUserId }) {



    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const handleClickVariant = (message, variant) => {
        enqueueSnackbar(message, { variant: variant, anchorOrigin: { vertical: 'bottom', horizontal: 'right' }, autoHideDuration: 1000 });
    };

    const handleSnackWithAction = ({ message, onHandle }) => {
        closeSnackbar()
        enqueueSnackbar(message, {
            anchorOrigin: {
                horizontal: "center",
                vertical: 'bottom'
            },
            variant: 'default',
            action: (key) => {
                return (
                    <React.Fragment>
                        <Stack direction="row" spacing={1}>
                            <Button
                                size='small'
                                color="error"
                                variant="contained"
                                onClick={() => {
                                    closeSnackbar(key)
                                    onHandle()
                                }}
                            >
                                <CheckRoundedIcon fontSize="small" />
                            </Button>
                            <Button size='small' variant="contained" color="success" onClick={() => closeSnackbar(key)}>
                                <CloseRoundedIcon fontSize="small" />
                            </Button>
                        </Stack>
                    </React.Fragment>
                )
            }
        });
    };


    const [form, setForm] = useState(data)
    const [open, setOpen] = useState(false)


    const [userId, setUserId] = useState("")

    useEffect(() => {
        setForm(data)
    }, [data])

    const [prefixs, setPrefixs] = useState([])
    const getPrefixs = () => {
        Api.get(`v0/if/Prefixs`, { headers: { CompanyGroup: dataAccount.cogcode } })
            .then((res) => {
                setPrefixs(res.data)
            })
            .catch((err) => {
                console.log('err', err)
            })

    }
    const handleOpen = () => {
        onHandleCheckUser()
        setOpen(true)
        getPrefixs()
    }
    const handleClose = () => {
        setOpen(false)
    }
    const handleSave = (event) => {
        event.preventDefault();
        setOpen(false)
        updateContact()
    }

    const [openUserId, setOpenUserId] = useState(false)
    const [formUser, setFormUser] = useState({
        "userid": "",
        "password": ""
    })
    const handleOpenUserId = () => {
        setOpenUserId(true)
    }
    const handleCloseUserId = () => {
        setOpenUserId(false)
    }
    const handleSaveUserId = (event) => {
        event.preventDefault();
        createUserID()
    }

    const updateContact = () => {
        const { cogcode, cocode } = dataAccount
        const data = JSON.stringify({
            ...form,
            cogcode: cogcode,
            cocode: cocode
        });
        Api.put(`/v0/if/Contact`, data, { headers: { CompanyGroup: cogcode } })
            .then((res) => {
                handleClickVariant("บันทึกสำเร็จ", "success")
                onHandleGet()
            })
            .catch((err) => {
                handleClickVariant("บันทึกไม่สำเร็จ", "error")
            })
    }


    const onHandleCheckUser = () => {
        const { id } = form
        Api.get(`/v0/app/GetUserID?id=${id}`)
            .then((res) => {
                const data = res.data
                setUserId(data)
            })
            .catch((err) => {
                setUserId(null)
            })

    }

    const handleChange = (e) => {
        const { name, value } = e.target
        setForm(s => {
            return {
                ...s,
                [name]: value
            }
        })
    }


    const createUserID = () => {
        // const { id } = contact
        const { id } = form
        const { cogcode, cocode } = dataAccount
        const data = JSON.stringify({
            ...formUser,
            id: id
        });
        Api.post(`/v0/app/CreateUserID`, data, { headers: { CompanyGroup: cogcode } })
            .then((res) => {
                handleClickVariant("บันทึกสำเร็จ", "success")
                onHandleGet()
                handleCloseUserId()
                onHandleCheckUser()
            })
            .catch((err) => {
                handleClickVariant("บันทึกไม่สำเร็จ", "error")
            })
    }
    const handleChangeUser = (e) => {
        const { name, value } = e.target
        setFormUser(s => {
            return {
                ...s,
                [name]: value
            }
        })
    }
    return (
        <Box>
            <IconButton onClick={handleOpen} size="small">
                <ModeEditIcon fontSize="small" />
            </IconButton>
            <Dialog
                open={open}
                maxWidth="sm"
                fullWidth={true}
                onClose={handleClose}
                slotProps={{
                    backdrop: {
                        style: {
                            // background: "#F8F8FF", opacity: 0.3
                            background: "#a7d8de", opacity: 0.2
                            // background: "#000", opacity: 0.1
                        }
                    }
                }}
            >
                <DialogTitle>
                    <Stack direction="row" spacing={1} justifyContent="space-between" sx={{ marginRight: "20px" }} >
                        <Typography variant="h6">
                            แก้ไขข้อมูล
                        </Typography>
                        {
                            userId === null && (
                                <Box>
                                    <Button size="small" onClick={handleOpenUserId}>
                                        สร้างบัญชีเข้าสู่ระบบ
                                    </Button>
                                </Box>

                            )
                        }
                        {
                            userId !== null && userId !== "" && (
                                <Box>
                                    <Stack spacing={1} direction="row">
                                        {/* <ResetPassword user={userId} /> */}
                                        <PermissionForm user={userId} dataUserId={dataUserId} />
                                        <ResetPassword user={userId} />
                                    </Stack>
                                </Box>

                            )
                        }
                    </Stack>
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    <Box>
                        <Stack >
                            <form id="FormSave" onSubmit={handleSave} >
                                <Grid container spacing={1}>
                                    <Grid item xs={12} md={12}>
                                        <TextField fullWidth size="small" label="คำนำหน้า" onChange={handleChange} value={form.prefixid} name='prefixid' select >
                                            {
                                                prefixs.map((prefix, index) => {
                                                    return <MenuItem key={`prefix${index}`} value={prefix.prefixid} >{prefix.prefixname}</MenuItem>
                                                })
                                            }
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <TextField fullWidth size="small" label="ชื่อ" onChange={handleChange} value={form.firstname} name='firstname' required />
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <TextField fullWidth size="small" label="นามสกุล" onChange={handleChange} value={form.lastname} name='lastname' />
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <TextField fullWidth size="small" label="วันเกิด" onChange={handleChange} value={form.birthday} name='birthday' />
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <TextField fullWidth size="small" label="เบอร์โทร" onChange={handleChange} value={form.phone} name='phone' />
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <TextField fullWidth size="small" label="email" onChange={handleChange} value={form.email} name='email' />
                                    </Grid>
                                    {/* <Grid item xs={12} md={12}>
                                        <TextField fullWidth size="small" label="เลขที่ผู้เสียภาษี" onChange={handleChange} value={form.taxid} name='taxid' />
                                    </Grid> */}
                                </Grid>
                            </form>
                        </Stack>
                    </Box>
                </DialogContent >
                <DialogActions>
                    <Button type="submit" form="FormSave">บันทึก</Button>
                </DialogActions>
            </Dialog >



            <Dialog
                open={openUserId}
                maxWidth="sm"
                fullWidth={true}
                onClose={handleCloseUserId}
                slotProps={{
                    backdrop: {
                        style: {
                            // background: "#F8F8FF", opacity: 0.3
                            background: "#a7d8de", opacity: 0.2
                            // background: "#000", opacity: 0.1
                        }
                    }
                }}
            >
                <DialogTitle>สร้างบัญชีเข้าสู่ระบบ</DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleCloseUserId}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    <Box>
                        <Stack >
                            <form id="FormSaveUserId" onSubmit={handleSaveUserId}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} md={12}>
                                        <TextField fullWidth size="small" label="userid" onChange={handleChangeUser} value={formUser.userid} name='userid' required />
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <TextField fullWidth size="small" type="password" label="password" onChange={handleChangeUser} value={formUser.password} name='password' required />
                                    </Grid>
                                </Grid>
                            </form>
                        </Stack>
                    </Box>
                </DialogContent >
                <DialogActions>
                    <Button type="submit" form="FormSaveUserId">บันทึก</Button>
                </DialogActions>
            </Dialog >
        </Box >
    )
}



const ResetPassword = ({ user }) => {
    const { id, userid } = user
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const handleClickVariant = ({ message, variant }) => {
        enqueueSnackbar(message, { variant: variant, anchorOrigin: { vertical: 'bottom', horizontal: 'right' }, autoHideDuration: 1000 });
    };

    const [openUserId, setOpenUserId] = useState(false)
    const [formUser, setFormUser] = useState({
        "id": id,
        "userid": userid,
        "password": ""
    })
    const handleOpenUserId = () => {
        setOpenUserId(true)
    }
    const handleCloseUserId = () => {
        setOpenUserId(false)
    }
    const handleSaveUserId = () => {
        const data = JSON.stringify({
            id: formUser.id,
            userid: formUser.userid,
            password: formUser.password
        });
        Api.put('v0/app/resetpassword', data)
            .then(res => {
                handleCloseUserId()
                handleClickVariant({ variant: 'success', message: 'บันทึกสำเร็จ' })
                // userActions.setAuthInfo({ dataAuth: res.data })
            }
            ).catch(error => {
                handleClickVariant({ variant: 'error', message: "บันทึกไม่สำเร็จ" });
            }
            )
    }

    const handleChangeUser = (e) => {
        const { name, value } = e.target
        setFormUser(s => {
            return {
                ...s,
                [name]: value
            }
        })
    }

    return (
        <>
            <Button size="small" onClick={handleOpenUserId}>
                แก้ไขรหัสผ่าน
            </Button>
            <Dialog
                open={openUserId}
                maxWidth="sm"
                fullWidth={true}
                onClose={handleCloseUserId}
                slotProps={{
                    backdrop: {
                        style: {
                            // background: "#F8F8FF", opacity: 0.3
                            background: "#a7d8de", opacity: 0.2
                            // background: "#000", opacity: 0.1
                        }
                    }
                }}
            >
                <DialogTitle>เปลี่ยนรหัสผ่าน</DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleCloseUserId}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    <Box>
                        <Stack >
                            <Grid container spacing={1}>
                                <Grid item xs={12} md={12}>
                                    <TextField fullWidth disabled size="small" label="userid" onChange={handleChangeUser} value={formUser.userid} name='userid' />
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <TextField fullWidth size="small" type="password" label="password" onChange={handleChangeUser} value={formUser.password} name='password' />
                                </Grid>
                            </Grid>
                        </Stack>
                    </Box>
                </DialogContent >
                <DialogActions>
                    <Button onClick={handleSaveUserId}>บันทึก</Button>
                </DialogActions>
            </Dialog >
        </>
    )
}



const PermissionForm = ({ user, dataUserId }) => {

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const handleClickVariant = (message, variant) => {
        enqueueSnackbar(message, { variant: variant, anchorOrigin: { vertical: 'bottom', horizontal: 'right' }, autoHideDuration: 1000 });
    };
    const handleSnackWithAction = ({ message, onHandle }) => {
        closeSnackbar()
        enqueueSnackbar(message, {
            anchorOrigin: {
                horizontal: "center",
                vertical: 'bottom'
            },
            variant: 'default',
            action: (key) => {
                return (
                    <React.Fragment>
                        <Stack direction="row" spacing={1}>
                            <Button
                                size='small'
                                color="error"
                                variant="contained"
                                onClick={() => {
                                    closeSnackbar(key)
                                    onHandle()
                                }}
                            >
                                <CheckRoundedIcon fontSize="small" />
                            </Button>
                            <Button size='small' variant="contained" color="success" onClick={() => closeSnackbar(key)}>
                                <CloseRoundedIcon fontSize="small" />
                            </Button>
                        </Stack>
                    </React.Fragment>
                )
            }
        });
    }

    const { id, userid } = user
    const [openUserId, setOpenUserId] = useState(false)
    const [openInheritedFromUser, setOpenInheritedFromUser] = useState(false)
    const [inheritPermission, setInheritPermission] = useState([])
    const [inheritMode, setInheritMode] = useState(true)


    const [openInherit, setOpenInherit] = useState(false)
    const [inheritPost, setInheritPost] = useState({
        "appid": "sitgps",
        "userid": userid,
        "permission": "",
        "name": "",
    })

    const [inheritListPost, setInheritListPost] = useState([])
    const [permission, setPermission] = useState([])



    const [formUser, setFormUser] = useState({
        "id": id,
        "userid": userid,
        "password": ""
    })

    const onHandleGetUserPermisson = () => {
        Api.get(`/v0/if/permission_u?userid=${userid}`)
            .then((res) => {
                setPermission(_.orderBy(res.data, ["name"], ["asc"]))
            })
            .catch(err => {
                setPermission([])
            })
    }


    useEffect(() => {
        Api.get(`/v0/if/permission_u?userid=${userid}`)
            .then((res) => {
                setPermission(_.orderBy(res.data, ["name"], ["asc"]))
            })
    }, [userid])



    const handleInheritedFromUser = () => {
        setOpenInheritedFromUser(true)
    }
    const handleCloseInheritedFromUser = () => {
        setOpenInheritedFromUser(false)
        setSearch('')
    }


    const onClickInheritedFromUser = () => {
        Api.get(`/v0/if/permission?userid=${dataUserId}`)
            .then((res) => {
                setInheritPermission(_.orderBy(res.data, ["name"], ["asc"]))
                handleInheritedFromUser()
            })
    }

    const onGetFromAdmin = ({ opt, mode }) => {
        const { name, value, permission } = opt
        Api.get(`/v0/if/permission?userid=${dataUserId}&permission=${permission}`)
            .then((res) => {
                const permissionInherit = res.data
                handleOpenGetFromAdmin({ opt: opt, mode: mode, permissionInherit })
            })
    }

    const handleOpenGetFromAdmin = ({ opt, mode, permissionInherit }) => {
        const { name, value, permission } = opt
        const adminp = JSON.parse(permissionInherit.value)
        const accp = JSON.parse(value)
        const permissionparse = adminp.map(admin => {
            const isfind = accp.find(v => v.id === admin.id)
            if (isfind !== undefined) {
                return { ...isfind }
            } else {
                return { ...admin, value: false }
            }
        })

        setInheritMode(mode)
        setInheritPost(inherit => {
            return {
                ...inherit, name: name, permission: permission
            }
        })
        setInheritListPost(permissionparse)
        handleCloseInheritedFromUser()
        setOpenInherit(true)
    }



    const handleOpenInherit = ({ opt, mode }) => {
        const { name, value, permission } = opt
        setInheritMode(mode)
        setInheritPost(inherit => {
            return { ...inherit, name: name, permission: permission }
        })
        setInheritListPost(JSON.parse(value))
        handleCloseInheritedFromUser()
        setOpenInherit(true)
    }
    const handleCloseInherit = () => {
        setOpenInherit(false)
        if (inheritMode) {
            handleInheritedFromUser()
        }
    }
    const handleOnSaveInherit = () => {
        const dataTopost = {
            "appid": inheritPost.appid,
            "permission": inheritPost.permission,
            "name": inheritPost.name,
            "userid": inheritPost.userid,
            "value": JSON.stringify(inheritListPost)
            // "value": JSON.stringify(inheritListPost.filter(item => item.value === true))
        }
        Api.post(`/v0/if/permission_u`, dataTopost)
            .then((res) => {
                setOpenInherit(false)
                onHandleGetUserPermisson()
                handleClickVariant("บันทึกสำเร็จ", "success")
            })
            .catch((err) => {
                handleClickVariant("บันทึกไม่สำเร็จ", "error")
            })
    }




    const handleOpenUserId = () => {
        setOpenUserId(true)
    }
    const handleCloseUserId = () => {
        setOpenUserId(false)
        setSearch('')
    }
    const handleSaveUserId = () => {
        resetPasswordUserID()
    }

    const resetPasswordUserID = () => {

    }

    const handleChangeUser = (e) => {
        const { name, value } = e.target
        setFormUser(s => {
            return {
                ...s,
                [name]: value
            }
        })
    }

    const handleChangeSwitch = (index, event) => {
        const { name, checked } = event.target
        const newData = [...inheritListPost];
        newData[index][name] = checked
        setInheritListPost(newData)

    };


    const handleDeletePermisson = (dataTopost) => {
        Api.delete(`/v0/if/permission_u`, { data: dataTopost })
            .then((res) => {
                handleClickVariant("ลบสำเร็จ", "success")
                onHandleGetUserPermisson()
            })
            .catch((err) => {
                handleClickVariant("ลบไม่สำเร็จ", "error")
            })
    }

    const [search, setSearch] = useState('')

    const onChangeSearch = (e) => {
        const { value } = e.target
        setSearch(value)

    }

    return (
        <>
            <Button size="small" onClick={handleOpenUserId}>
                กำหนดสิทธิ์
            </Button>
            {/* //////////////////// */}
            <Dialog
                open={openUserId}
                maxWidth="sm"
                fullWidth={true}
                onClose={handleCloseUserId}
                slotProps={{
                    backdrop: {
                        style: {
                            background: "#a7d8de", opacity: 0.2
                        }
                    }
                }}
            >
                <DialogTitle>
                    <Stack direction="row" spacing={1}>
                        <Box sx={{ marginTop: "8px" }}>
                            กำหนดสิทธิ์
                        </Box>
                        <TextField size="small" onChange={onChangeSearch} value={search} placeholder='search' />
                    </Stack>
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleCloseUserId}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    <Box sx={{ justifyContent: "end", display: "flex", width: "100%" }}>
                        <IconButton size="small" onClick={onClickInheritedFromUser}>
                            <AddBoxRoundedIcon />
                        </IconButton>
                    </Box>
                    <Box>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>permission</TableCell>
                                    <TableCell>name</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    permission.filter(v => v?.name?.toLowerCase().includes(search.toLowerCase())).map((item, index) => {
                                        return (
                                            <TableRow key={index}>
                                                <TableCell>{item.permission}</TableCell>
                                                <TableCell>{item.name}</TableCell>
                                                <TableCell sx={{ justifyContent: "end", display: "flex" }}>
                                                    <Stack spacing={1} direction="row">
                                                        <IconButton size="small" onClick={() => { onGetFromAdmin({ opt: item, mode: false }) }}>
                                                            <SettingsApplicationsRoundedIcon />
                                                        </IconButton>
                                                        <IconButton size="small" onClick={() => { handleSnackWithAction({ message: "ต้องการลบ?", onHandle: () => handleDeletePermisson(item) }) }}>
                                                            <DeleteOutlineRoundedIcon />
                                                        </IconButton>
                                                    </Stack>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })
                                }
                            </TableBody>
                        </Table>
                    </Box>
                </DialogContent>
            </Dialog >
            {/* //////////////////// */}
            <Dialog
                open={openInheritedFromUser}
                maxWidth="sm"
                fullWidth={true}
                onClose={handleCloseInheritedFromUser}
                slotProps={{
                    backdrop: {
                        style: {
                            background: "#a7d8de", opacity: 0.2
                        }
                    }
                }}
            >
                <DialogTitle>
                    <Stack direction="row" spacing={1}>
                        <Box sx={{ marginTop: "8px" }}>
                            เลือกสิทธิ์
                        </Box>
                        <TextField size="small" onChange={onChangeSearch} value={search} placeholder='search' />
                    </Stack>
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleCloseInheritedFromUser}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    <Box>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>permission</TableCell>
                                    <TableCell>name</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    inheritPermission.filter(v => v?.name?.toLowerCase().includes(search.toLowerCase())).map((item, index) => {
                                        return (
                                            <TableRow key={index}>
                                                <TableCell>{item.permission}</TableCell>
                                                <TableCell>{item.name}</TableCell>
                                                <TableCell sx={{ justifyContent: "end", display: "flex" }}>
                                                    <IconButton size="small" onClick={() => { handleOpenInherit({ opt: item, mode: true }) }}>
                                                        <SettingsApplicationsRoundedIcon />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })
                                }
                            </TableBody>
                        </Table>
                    </Box>
                </DialogContent >
            </Dialog >
            {/* //////////////////// */}
            <Dialog
                open={openInherit}
                maxWidth="sm"
                fullWidth={true}
                onClose={handleCloseInherit}
                slotProps={{
                    backdrop: {
                        style: {
                            background: "#a7d8de", opacity: 0.2
                        }
                    }
                }}
            >
                <DialogTitle>
                    กำหนดสิทธิ์
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleCloseInherit}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    <Box>
                        <Grid container>
                            {
                                inheritListPost.map((opt, index) => {
                                    return (
                                        <React.Fragment key={index}>
                                            <Grid item xs={8}>{opt.name}</Grid>
                                            <Grid item xs={4} justifyContent="end" display="flex">
                                                <Switch
                                                    name='value'
                                                    checked={opt.value}
                                                    onChange={(e) => handleChangeSwitch(index, e)}
                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                />
                                            </Grid>
                                        </React.Fragment>
                                    )
                                })
                            }
                        </Grid>
                    </Box>
                </DialogContent >
                <DialogActions>
                    <Button onClick={handleOnSaveInherit}>บันทึก</Button>
                </DialogActions>
            </Dialog >
        </>
    )
}