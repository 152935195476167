import React, { useEffect, useState } from 'react'
import { Box, Collapse, TextField, Badge, ButtonGroup, Stack, Grid, Checkbox, Typography, Paper, TableContainer, Table, TableBody, TableHead, TableRow, TableCell, Button, IconButton, InputAdornment, Dialog, DialogContent, DialogTitle, DialogActions, Divider, Autocomplete, Tooltip } from '@mui/material'
import data from '@iconify/icons-eva/menu-2-fill';
import DialogEventsCars from './DialogEventsCars';
import zIndex from '@mui/material/styles/zIndex';
import DialogViewWork from './DialogViewWork';
import BookingCreateCarsOverView from './BookingCreateCarsOverView';
import _ from 'lodash';

export default function TableCars({ getDataBnE, dataFillter, handleEventClick, maxDate, year, month, userId }) {
    const [dateBetween, setDateBetween] = useState({
        vehicle_id: "",
        date_start: "",
        date_end: ""
    });



    useEffect(() => {
        const handleEscape = (event) => {
            if (event.keyCode === 27 && dateBetween?.date_start !== "") {
                setDateBetween({
                    vehicle_id: "",
                    date_start: "",
                    date_end: ""
                })
            }
        };

        window.addEventListener('keydown', handleEscape);
        return () => {
            window.removeEventListener('keydown', handleEscape);
        };
    }, [dateBetween]);


    const [isTooltipVisible, setIsTooltipVisible] = useState(false);
    const [tooltipData, setTooltipData] = useState({ title: "", data: "" });

    const handleMouseEnter = (day, Ranges) => {
        const data = filterEventInfo(day, Ranges)
        // const data = getEventInfo(day, Ranges)
        if (data) {
            const tooltipData = { title: "", data: data }
            setTooltipData(tooltipData)
            setIsTooltipVisible(true);
            console.log('tooltipData', tooltipData)
        }
    };

    const handleMouseLeave = () => {
        setIsTooltipVisible(false);
    };

    const onSelect = ({ vehicle_id, date }) => {
        const data = dateBetween.date_start
        if (data === "") {
            setDateBetween(e => ({
                ...e,
                vehicle_id: vehicle_id,
                date_start: `${date} 00:00:00`,
                date_end: ""
            }))
        } else {
            const date_end = `${date} 23:59:00`
            setDateBetween(d => { return { ...d, date_end: date_end < dateBetween.date_start ? "" : date_end } })
        }
    }




    const getBackgroundColor = (date, vehicle_id, ranges) => {
        const event = ranges?.find((event) =>
            date >= event.date_start && date <= event.date_end
        );
        return event ?
            (event?.color) ? event?.color : "#678fd4" :
            (dateBetween.date_start !== "" && dateBetween.vehicle_id === vehicle_id) ? date >= dateBetween.date_start && date <= (dateBetween.date_end !== "" ? dateBetween.date_end : dateBetween.date_start) ? "#e9e6ee" : 'white' : 'white';
    };

    // const getEventInfo = (date, ranges) => {
    //     const event = ranges?.find(
    //         (event) => date >= event.date_start && date <= event.date_end
    //     );
    //     return event?.event ? { ...event } : ""; // Return empty string if no event found
    //     // return event?.event ? `${event?.event}(${event.date_start} - ${event.date_end}` : ""; // Return empty string if no event found
    // };

    const filterEventInfo = (date, ranges) => {
        if (ranges.length > 0) {
            return ranges.filter((d) => date >= d.date_start && date <= d.date_end)
        } else {
            return []
        }
        // const event = dataFillter?.find(
        //     (event) => date >= event.date_start && date <= event.date_end
        // );
    };

    const [isOpenEvents, setIsOpenEvents] = useState(false);

    const handleEventsOpen = (data) => {
        setIsOpenEvents(true)
    }
    const handleEventsClose = () => {
        setIsOpenEvents(false)
        getDataBnE()
        setDateBetween({
            vehicle_id: "",
            date_start: "",
            date_end: ""
        })
        console.log('data', data)
    }
    const handleEventsCancel = () => {
        setIsOpenEvents(false)
    }

    const [isOpenWork, setIsOpenWork] = useState(false)
    const [dataViewWork, setDataViewWork] = useState([])
    const handleViewWorkClose = () => {
        setIsOpenWork(false)
        getDataBnE()
    }


    const [isOpenCreate, setIsOpenCreate] = useState(false)
    const handleCreateOverViewOpen = () => {
        setIsOpenCreate(true)
    }
    const handleCreateOverViewClose = () => {
        setIsOpenCreate(false)
        setDateBetween({
            vehicle_id: "",
            date_start: "",
            date_end: ""
        })
        getDataBnE()
    }
    const handleCreateOverViewCancel = () => {
        setIsOpenCreate(false)
        getDataBnE()
    }

    const handleEscClose = () => {
        setIsOpenWork(false)
    }
    return (
        <>
            <DialogEventsCars userId={userId} open={isOpenEvents} onCancel={handleEventsCancel} onClose={handleEventsClose} data={dateBetween} />
            <DialogViewWork userId={userId} open={isOpenWork} onCloseEsc={handleEscClose} onClose={handleViewWorkClose} data={dataViewWork} />
            <BookingCreateCarsOverView userId={userId} open={isOpenCreate} data={dateBetween} handleCancel={handleCreateOverViewCancel} handleClose={handleCreateOverViewClose} />
            <Dialog onClose={() => {
                setDateBetween({
                    vehicle_id: "",
                    date_start: "",
                    date_end: ""
                })
            }} open={(dateBetween.vehicle_id !== "" && dateBetween.date_start !== "" && dateBetween.date_end !== "")}>
                <DialogContent>
                    <Box>
                        <Box marginTop="8px" marginBottom="10px">
                            <Stack spacing={1}>
                                <Box>
                                    <TextField size="small" value={dateBetween?.vehicle_id} fullWidth label="ทะเบียน" disabled />
                                </Box>
                                <Box>
                                    <Stack direction="row" spacing={1}>
                                        <TextField type="datetime-local" disabled size="small" value={dateBetween?.date_start} label="วันที่เริ่ม" />
                                        <TextField type="datetime-local" disabled size="small" value={dateBetween?.date_end} label="วันที่สิ้นสุด" />
                                    </Stack>
                                </Box>
                            </Stack>
                        </Box>
                        <Box>
                            <Stack direction="column" spacing={1}>
                                <Box justifyContent="center" display="flex">
                                    <Button onClick={() => { handleCreateOverViewOpen() }}>สร้าง booking</Button>
                                </Box>
                                {/* <Box justifyContent="center" display="flex">
                                    <Button onClick={() => { handleEventClick(dateBetween) }}>อัพเดท booking</Button>
                                </Box> */}
                                <Box justifyContent="center" display="flex">
                                    <Button onClick={() => { handleEventsOpen(dateBetween) }}>สร้าง events</Button>
                                </Box>
                            </Stack>
                        </Box>
                    </Box>
                </DialogContent>
            </Dialog>
            {
                dataFillter.map((rowdata, index) => {
                    return (
                        <TableRow key={`car-row-${index}`}>
                            <TableCell style={{
                                position: 'sticky', whiteSpace: "nowrap", left: 0, zIndex: 2,
                                // borderRight: 1,
                                // borderLeft: 0,
                                // borderTop: 0,
                                // borderBottom: 1,
                                // borderStyle: "solid",
                                backgroundColor: "white"
                            }}>
                                <Box marginX={"5px"}>
                                    {`${rowdata.vehicle_id}`}
                                </Box>
                            </TableCell>
                            {
                                // (dataFillter.find(d => d.vehicle_id === rowdata.vehicle_id)) &&
                                (
                                    [...Array(maxDate)].map((value, index) => {
                                        const day = (index + 1).toString().padStart(2, "0")
                                        const data = filterEventInfo(`${year}-${month}-${day} 00:00:00`, rowdata.data)
                                        const dataDate = _.uniqBy(data.map(d => { return { "date_start": d.date_start?.split(" ")[0] } }), "date_start")
                                        return (
                                            <TableCell
                                                key={`car-TableCell-row-${index}`}
                                                onClick={() => {
                                                    if (data.length <= 0) {
                                                        const data = {
                                                            vehicle_id: rowdata.vehicle_id,
                                                            type_car: rowdata.standard,
                                                            date: `${year}-${month}-${day}`
                                                        }
                                                        onSelect(data)
                                                    } else {
                                                        // if (dateBetween.date_start !== "") {
                                                        //     const data = {
                                                        //         vehicle_id: rowdata.vehicle_id,
                                                        //         type_car: rowdata.standard,
                                                        //         date: `${year}-${month}-${day}`
                                                        //     }
                                                        //     onSelect(data)
                                                        // } else {
                                                        //     setIsOpenWork(true)
                                                        //     setDataViewWork(filterEventInfo(`${year}-${month}-${day} 00:00:00`, rowdata.data))
                                                        // }
                                                        setIsOpenWork(true)
                                                        setDataViewWork(filterEventInfo(`${year}-${month}-${day} 00:00:00`, rowdata.data))
                                                    }
                                                }}
                                                sx={{
                                                    '&:hover': {
                                                        backgroundColor: dateBetween.vehicle_id === "" ? 'lightblue' : dateBetween.vehicle_id === rowdata.vehicle_id ? "lightblue" : "white", opacity: "0.6"
                                                    },
                                                    cursor: "pointer",
                                                    position: 'sticky',
                                                    whiteSpace: "nowrap",
                                                    left: 0,
                                                    zIndex: 1,
                                                    padding: "0px",
                                                    // borderStyle: "solid",
                                                    // borderLeftWidth: "0px",
                                                    // borderRightWidth: "1px",
                                                    // borderTopWidth: "1px",
                                                    backgroundColor: getBackgroundColor(`${year}-${month}-${day} 00:00:00`, rowdata.vehicle_id, rowdata.data),
                                                }}
                                            >
                                                {
                                                    data.length > 0 ? (
                                                        <Tooltip
                                                            open={dataDate.filter(d => d.date_start === `${year}-${month}-${day}`).length > 0}
                                                            title={<BoxName data={data.filter(d => d.date_start.split(" ")[0] === `${year}-${month}-${day}`)} />} placement="right"
                                                            style={{ marginLeft: "0px" }}
                                                            slotProps={{
                                                                popper: {
                                                                    modifiers: [
                                                                        {
                                                                            name: 'offset',
                                                                            options: {
                                                                                offset: [0, -47],
                                                                            },
                                                                        },
                                                                    ],
                                                                    style: { zIndex: 1 }
                                                                },
                                                                tooltip: {
                                                                    sx: { userSelect: "none", cursor: "pointer", marginLeft: "1px !important", padding: "0px !important", borderRadius: "2px ", opacity: "0.8", borderColor: "white", border: "1px", borderStyle: "solid" }
                                                                }
                                                            }}
                                                        >
                                                            <Box sx={{ width: "47.58px", height: "35px" }}>
                                                            </Box>
                                                        </Tooltip>
                                                    ) :
                                                        (
                                                            <Box sx={{ width: "47.58px", height: "35px" }}></Box>
                                                        )
                                                }
                                            </TableCell>
                                        )
                                    })
                                )
                            }
                        </TableRow >
                    )
                })
            }
        </>
    )
}


const BoxName = ({ data }) => {
    const calculateDifference = (d) => {
        const start = new Date(d.date_start.split(" ")[0]);
        const end = new Date(d.date_end.split(" ")[0]);
        const differenceInMilliseconds = Math.abs(end - start);
        const differenceInDays = Math.ceil(differenceInMilliseconds / (1000 * 60 * 60 * 24));
        return differenceInDays + 1;
    };
    return (
        <>
            <Box sx={{ maxHeight: "25px", overflow: "hidden" }}>
                {data.map((d, i) => {
                    return (
                        <Box sx={{ maxWidth: `${43 * calculateDifference(d)}px`, whiteSpace: "nowrap" }}> {`${d.event_name || d.company_name || d.booking_id}`}</Box>
                    )
                })}
            </Box >
        </>
    )
}