import React, { useEffect, useState } from 'react'
import { Box, Collapse, TextField, Badge, ButtonGroup, Stack, Grid, Checkbox, Typography, Paper, TableContainer, Table, TableBody, TableHead, TableRow, TableCell, Button, IconButton, InputAdornment, Dialog, DialogContent, DialogTitle, DialogActions, Divider, Autocomplete, Tooltip, MenuItem } from '@mui/material'
import axios from 'axios'
import { da } from 'date-fns/locale'

export default function DialogEventsCars({ onClose, open, data, onCancel, userId }) {

    const [ifEvents, setIfEvents] = useState([])
    const [data_p, setData_p] = useState({
        "type": "vehicle",
        "ref": ``,
        "event_code": "",
        "event_name": "",
        "date_start": ``,
        "date_end": ``,
        "note": ""
    })
    useEffect(() => {
        setData_p({
            "type": "vehicle",
            "ref": `${data?.vehicle_id}`,
            "event_code": "",
            "event_name": "",
            "date_start": `${data?.date_start}`,
            "date_end": `${data?.date_end}`,
            "note": ""
        })
    }, [data])


    useEffect(() => {
        if (open) {
            axios.get(`https://booking.sitgps.com/booking/if_event`)
                .then(res => {
                    setIfEvents(res.data)
                })
                .catch(error => {
                    console.log('error', error)
                })
        }
    }, [open])


    const onSubmit = () => {
        const data = {
            "booking_userid": userId,
            "type": "vehicle",
            "ref": `${data_p?.ref}`,
            "event_code": `${data_p?.event_code}`,
            "event_name": `${data_p?.event_name}`,
            "date_start": `${data_p?.date_start}`,
            "date_end": `${data_p?.date_end}`,
            "note": ""
        }
        axios.post(`https://booking.sitgps.com/booking/event`, data)
            .then(res => {
                onClose()
            })
            .catch(error => {
                console.log('error', error)
            })
    }

    const onChangeSelectEvent = (values) => {
        console.log('values', values)
        setData_p(d => { return { ...d, ...values } })
    }

    const onChange = (e) => {
        const { name, value } = e.target
        setData_p(d => {
            return { ...d, [name]: value }
        })
    }

    return (
        <div>
            <Dialog onClose={onCancel} open={open}>
                <DialogTitle>Events</DialogTitle>
                <DialogContent>
                    <Box marginTop="8px">
                        <Stack spacing={1}>
                            <Box>
                                <TextField size="small" onChange={onChange} name="ref" value={data_p?.ref} fullWidth label="ทะเบียน" disabled />
                            </Box>
                            <Box>
                                <Stack direction="row" spacing={1}>
                                    <TextField size="small" onChange={onChange} name="date_start" value={data_p?.date_start} label="วันที่เริ่ม" type="datetime-local" />
                                    <TextField size="small" onChange={onChange} name="date_end" value={data_p?.date_end} label="วันที่สิ้นสุด" type="datetime-local" />
                                </Stack>
                            </Box>
                            <Box>
                                <TextField size="small"
                                    value={data_p?.event_code}
                                    fullWidth select
                                    label="เหตุการณ์"
                                    onChange={(e) => {
                                        const value = ifEvents.find(v => v.event_code === e.target.value)
                                        onChangeSelectEvent({ event_code: value.event_code, event_name: value.event_name, type: value.type })
                                    }}
                                >
                                    <MenuItem value={""}>กรุณาระบุ</MenuItem>
                                    {
                                        ifEvents.map((e, i) => {
                                            return (
                                                <MenuItem key={`event-${i}`} value={e.event_code}>{e.event_name}</MenuItem>
                                            )
                                        })
                                    }
                                </TextField>
                            </Box>
                            <Box>
                                <TextField onChange={onChange} name="note" size="small" multiline={true} rows={3} value={data_p?.note} fullWidth label="note" />
                            </Box>
                            <Box>
                                <Stack direction="row" spacing={1}>
                                    <Button variant="contained" onClick={onSubmit}>บันทึก</Button>
                                    <Button variant="outlined" onClick={onCancel}>ยกเลิก</Button>
                                </Stack>
                            </Box>
                        </Stack>
                    </Box>
                </DialogContent>
            </Dialog>
        </div>
    )
}
